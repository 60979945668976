import baseURL, { config } from "../Api/baseURL";
const loggedUser=JSON.parse(localStorage.getItem('user')); 
let isAdmin=false;
if(loggedUser?._id) isAdmin=true;


export const useUpdateData = async (url, params) => {
  const token = localStorage.getItem("token");

  const headers = {
    ...config.headers,
    authorization: token ? `${token}` : "",
    is_admin:`${isAdmin}`

  };

  // try {
    const res = await baseURL.put(url, params, { ...config, headers });
    console.log("resresresres",res)
    if(res?.data?.logout){
      if(!window.location.href.includes("?logout=true") ){
        window.location.href='/?logout=true'
        // localStorage.clear();

      }

    }
    return res.data;
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  //   console.log("Error2",error?.response)
   
  // }
};


export const useUpdateDataWithImage=async(url,params)=>{
    const updateConfig={
        headers: {
            "Content-Type": "multipart/form-data",
            authorization: `${localStorage.getItem("token")}`,
            is_admin:`${isAdmin}`
          }
    };
    const res=baseURL.put(url,params,updateConfig);
    return res;
}