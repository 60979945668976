import baseURL, { config } from "../Api/baseURL";
const loggedUser=JSON.parse(localStorage.getItem('user')); 
let isAdmin=false;
if(!loggedUser?._id) isAdmin=true;

export const useDeleteData=async(url,params)=>{
    const token = localStorage.getItem("token");

    const headers = {
      ...config.headers,
      authorization: token ? `${token}` : "", 
    };

    try {
        const res = await baseURL.delete(url, { ...config, headers, params });
        return res.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        if(error?.response?.data?.logout){
        localStorage.clear();
        }
      }
    };