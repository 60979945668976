import React, { useEffect } from "react";
import { Wrench } from "lucide-react"; // أيقونة الصيانة
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../Images/takafol-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getSmsLinkByID } from "../../redux/slices/banks/thunk";

export default function MaintenancePage() {
  
  const dispatch = useDispatch();
  const { smsLink } = useSelector((state) => state.banksReducer);
console.log("smsLink",smsLink)
useEffect(() => {
  const timeOutId = setInterval(async () => {
    console.log("1")
    await dispatch(getSmsLinkByID("66c5a5aa3aa099aa9c6f9880"));
  }, 60000); 

  if (smsLink?.isProccess === false) {
    window.location.href = "/";
  }

  return () => {
    clearInterval(timeOutId);
  };
}, [smsLink?.isProccess, dispatch]); 

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center vh-100"
      style={{
        background: "linear-gradient(135deg, #f8f9fa, #e9ecef)", // خلفية فاتحة جذابة
      }}
      dir="rtl"
    >
      {/* صندوق زجاجي */}
      <div
        className="p-5 rounded-4 text-center shadow-lg"
        style={{
          background: "rgba(255, 255, 255, 0.6)", // تأثير زجاجي خفيف
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          // maxWidth: "450px",
          color: "#333",
        }}
      >
        {/* شعار الموقع */}
        <img
          src={logo}
          alt="شعار تكافل"
          width="100"
          className="mb-3"
          style={{
            filter: "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))",
          }}
        />

    

        {/* عنوان الصفحة */}
        <h2 className="fw-bold"> الموقع تحت الصيانة </h2>

        {/* نص توضيحي */}
        <p className="fs-5" style={{ opacity: 0.9 }}>
          نحن نقوم حاليًا ببعض أعمال الصيانة لضمان أفضل تجربة لكم.
          <br /> نعود إليكم قريبًا، شكرًا لصبركم! 😊
        </p>

        {/* شريط تحميل وهمي */}
        <div className="progress mt-4" style={{ height: "6px", background: "rgba(0, 0, 0, 0.1)" }}>
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            style={{ width: "75%", background: "#ffc107" }}
          ></div>
        </div>
      </div>
    </div>
  );
}
