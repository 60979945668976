import { useNavigate, Navigate, Outlet  } from "react-router-dom";

export const ProtectedRoute=({route,auth,children,screenID})=>{
    console.log('auth',auth);
    const loggedUser=JSON.parse(localStorage.getItem('user')); 

    if(!loggedUser){
      return window.location.href='/'

     }

    

    if(auth==false || auth==undefined) return <Navigate  to='/' />;
    
    return <Outlet />;
}