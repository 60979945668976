import baseURL, { config } from "../../Api/baseURL";
const loggedUser=JSON.parse(localStorage.getItem('user')); 

export const login=async ({username,password})=>{

      const headers = {
        ...config.headers,
        is_admin:`true`
    
      };
    try {
        const data={username,password};

    const response=await baseURL.post('/api/v1/users/login',data, { headers });

    return response;
    //console.log('response',response);        
    } catch (error) {
        //console.error(error.response.data);

        return error.response;
    }

}