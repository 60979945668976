import axios from "axios";

const baseURL=axios.create({baseURL:"https://takafula.com/"});
 const loggedUser=JSON.parse(localStorage.getItem('user')); 
 let isAdmin=false;
 if(!loggedUser?._id) isAdmin=true

export const config={
    headers:{
        authorization:`${localStorage.getItem("token")}`,
        // is_admin:`true`
    }
};
export default baseURL;