import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useInsertData } from "../../../hooks/useInsertData";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useUpdateData } from "../../../hooks/useUpdateData";

export const getUserAgents = createAsyncThunk(
  "/marketingSlice/getUserAgents",
  async (query) => {
    try {
      const response = await useGetDataToken(`/api/v1/marketers?${query}`);
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error, "error");
    }
  }
);

export const addAgentToUser = createAsyncThunk(
  "/marketingSlice/addAgentToUser",
  async ({ data }) => {
    try {
      const response = await useInsertData("/api/v1/marketers", data);
      if (response?._id) notify("تم الاضافة بنجاح", "success");
      return response;
    } catch (error) {
      console.log(error);
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data.error, "error");
    }
  }
);

export const updateAgentUser = createAsyncThunk(
  "/marketingSlice/updateAgentUser",
  async ({ id, data }) => {
    try {
      const response = await useUpdateData(`/api/v1/marketers/${id}`, data);

      if (response?._id) 

      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.message, "error");
    }
  }
);

export const getAllMarketers = createAsyncThunk(
  "/marketingSlice/getAllMarketers",
  async (query) => {
    try {
      const response = await useGetDataToken(`/api/v1/marketers?${query}`);
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.message, "error");
    }
  }
);

export const getMarketerReport = createAsyncThunk(
  "/marketingSlice/getMarketerReport",
  async (query) => {
    try {
      const response = await useGetDataToken(`/api/v1/reports?${query}`, {
        headers: {
            is_admin: true,
        }
    });
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.message, "error");
    }
  }
);
