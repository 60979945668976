import baseURL, { config } from "../Api/baseURL";
const loggedUser=JSON.parse(localStorage.getItem('user')); 
let isAdmin=false;
// if(!loggedUser?._id) isAdmin=true;

export const useGetData = async (url, params) => {
  const token = localStorage.getItem("token");
  let isAdmin = true
  console.log("loggedUser",loggedUser)
  if(loggedUser){
   isAdmin = false
  }
  const headers = {
    ...config.headers,
    authorization: token ? `${token}` : "", 
    is_admin:isAdmin

  };

  try {
    if(loggedUser?.token){
      const res = await baseURL.get(url, { ...config, headers, params });
      return res.data;
    }
  } catch (error) {
      console.error("Error fetching data:", error);
      console.log("Error2",error?.response)
      if(error?.response?.data?.logout){
        if(!window.location.href.includes("?logout=true") ){
          window.location.href='/?logout=true'
          localStorage.clear();
  
        }

      }
  }
};

export const useGetDataToken=async(url,params)=>{

    const token = localStorage.getItem("token");
    const loggedUser = localStorage.getItem("user");
   let isAdmin = true
   console.log("loggedUser",loggedUser)
   if(loggedUser){
    isAdmin = false
   }

    const headers = {
      ...config.headers,
      authorization: token ? `${token}` : "", 
      is_admin:isAdmin

    };
  
    // try {
      // if(loggedUser?.token){
      const res = await baseURL.get(url, { ...config, headers, params });
      console.log("resresresres",res," -->", res.status)
      // if(res?.data?.logout){
      if(res.status ==256){
        if(!window.location.href.includes("?logout=true") ){
          window.location.href='/?logout=true'
          // localStorage.clear();
  
        }
      }

      return res.data;
      // }
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    //   console.log("Error21",error?.response)
      

    //   throw error;
    // }
}