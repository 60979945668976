import { createSlice } from "@reduxjs/toolkit";
import { getAllUsers,getAllAdmins, updateOneUser , addOneUser, getOneUser,resetUsers,getUsersByCity, getUsersByCountry, getLiquidationUsers, getUserSummary, getAllUsersWithPagination,updateUserProfile, updateOfferComission, allSupervisorsByCountry } from "./thunk";

const initialState={
    users:[],
    updatedUser:{},
    addUser:{},
    oneUser:null,
    usersByCity:[],
    usersByCountry:[],
    error:null,
    isLoading:false,
    liquidationUsers:[],
    accountantUsers:[],
    usersummary:[],
    usersWithPagination:[],
    offerComission:{},
    supervisors:[]
};

const usersSlice=createSlice({
        initialState,
        name:"usersSlice",
        reducers: {
            // New action to reset usersummary
            resetUserSummary: (state) => {
                state.usersummary = {
                    sumOfCards: 0,
                    money_for_company: 0,
                    priceOfDeposits: 0,
                    sumOfSalesCommission: 0,
                };
            },
        },
                extraReducers:(builder)=>{
            builder.addCase(getAllUsers.fulfilled,(state,action)=>{
                state.users=action?.payload;
            });
            builder.addCase(getAllUsers.rejected,(state,action)=>{
                state.error=action.payload;
            });

            builder.addCase(getAllAdmins.fulfilled,(state,action)=>{
                state.users=action?.payload;
            });
            builder.addCase(allSupervisorsByCountry.fulfilled,(state,action)=>{
                state.supervisors=action?.payload;
            });

            builder.addCase(updateOneUser.fulfilled,(state,action)=>{

                //console.log('action.payload',action.payload);

                state.usersWithPagination.data = state.usersWithPagination.data?.map((user) =>
                user?._id.toString() === action.payload?._id.toString()
                  ? { ...action.payload }
                  : user
                  );

                //   state?.updatedUser=action?.payload
                //   state.isLoading=false;
            });

            builder.addCase(updateOneUser.pending,(state,action)=>{
                state.isLoading=true;
            });

            builder.addCase(updateOneUser.rejected,(state,action)=>{
                state.isLoading=false;
                state.error=action.payload;
            });

            builder.addCase(addOneUser.pending,(state,action)=>{
                state.isLoading=true;
            });

            builder.addCase(addOneUser.rejected,(state,action)=>{
                state.isLoading=false;
                state.error=action.payload;
            });

            builder.addCase(addOneUser.fulfilled,(state,action)=>{
                state.isLoading=false;
                //state.addUser=action.payload; 
                state.users=[...state.users?.data,action?.payload];
            });

            builder.addCase(getOneUser.pending,(state,action)=>{
                //state.oneUser=action.payload;
                state.isLoading=true;
            });
        
            builder.addCase(getOneUser.fulfilled,(state,action)=>{
                state.offerComission=action?.payload;
                state.oneUser=action?.payload;
                state.isLoading=false;
            });
            builder.addCase(getOneUser.rejected,(state,action)=>{
                //state.oneUser=action.payload;
                state.isLoading=false;
            });

            builder.addCase(resetUsers.fulfilled,(state,action)=>{
                state.users=action?.payload;
            });

            builder.addCase(getUsersByCity.fulfilled,(state,action)=>{
                state.usersByCity=action?.payload?.filter(
                    el=>el.liquidation_user
                );
            });
            builder.addCase(getUsersByCountry.fulfilled,(state,action)=>{
                state.usersByCountry=action?.payload?.filter(
                    el=>el.liquidation_user
                );
            });
            builder.addCase(getLiquidationUsers.fulfilled,(state,action)=>{
            state.liquidationUsers=action?.payload?.filter(
                el=>el.liquidation_user
            );

            state.accountantUsers=action?.payload?.filter(
                el=> el.is_accountant
            )
            });

            builder.addCase(getUserSummary.fulfilled,(state, action)=>{
                state.usersummary=action?.payload
            });

            builder.addCase(getAllUsersWithPagination.fulfilled,(state,action)=>{
                if(action?.payload?.data)
                state.usersWithPagination=action?.payload
            });

            builder.addCase(updateUserProfile.fulfilled,(state,action)=>{
                state.oneUser=action.payload;
            });

            builder.addCase(updateOfferComission.fulfilled,(state,action)=>{
                state.oneUser=action.payload;
                state.isLoading=false;
            });
            
        }
        
});

//getLiquidationUsers
export const { resetUserSummary } = usersSlice.actions;
export default usersSlice.reducer;