import baseURL from "../Api/baseURL";
const loggedUser=JSON.parse(localStorage.getItem('user')); 
let isAdmin=false;
if(!loggedUser?._id) isAdmin=true;



export const useInsertData=async(url,params)=>{
    let configInsert={
        headers: {
            authorization: `${localStorage.getItem("token")}`,
            is_admin:`${isAdmin}`
          },
    
    };
    // try{
        const res=await baseURL.post(url,params,configInsert);

        if(res?.data?.logout){
          if(!window.location.href.includes("?logout=true") ){
            window.location.href='/?logout=true'
            localStorage.clear();
    
          }
  
        }

        return res.data;
    // } catch(error){

    // }
}
export const useInsertAllData=async(url,params)=>{
    let configInsert={
        headers: {
            authorization: `${localStorage.getItem("token")}`,
            is_admin:`${isAdmin}`
          },
    
    };
    // try{
        const res=await baseURL.post(url,params,configInsert);

        if(res?.data?.logout){
          if(!window.location.href.includes("?logout=true") ){
            window.location.href='/?logout=true'
            localStorage.clear();
    
          }
  
        }

        return res;
    // } catch(error){

    // }
}



export const useInsertDataWithImage=async(url,params)=>{
    let configInsert={
        headers: {
            "Content-Type": "multipart/form-data",
            authorization: `${localStorage.getItem("token")}`,
            is_admin:`${isAdmin}`
          },
    
    };
    const res=await baseURL.post(url,params,configInsert);
    return res;
}