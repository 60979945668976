import { createSlice } from "@reduxjs/toolkit";
import { addNewDeposite, getAllDepositesForUser, getAllDepositesForUserWithPagination, updateOneDeposite } from "./thunk";

const initialState={
    deposites:[],
    depositesWithPaginatioon:[],
    lastDeposite:null
};

const depositesSlice=createSlice({
    initialState,
    name:'depositesSlice',
    reducres:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllDepositesForUser.fulfilled,(state,action)=>{
            state.deposites=action.payload;
        });
        builder.addCase(addNewDeposite.fulfilled,(state,action)=>{
            state.deposites=[...state.deposites,action?.payload]
            state.lastDeposite=action?.payload
        });
        builder.addCase(updateOneDeposite.fulfilled,(state,action)=>{
            state.depositesWithPaginatioon.data = state.depositesWithPaginatioon.data.map((deposite) =>
                deposite?._id.toString() === action.payload?._id.toString()
                  ? { ...deposite, ...action.payload }
                  : deposite
                  );
        });

        builder.addCase(getAllDepositesForUserWithPagination.fulfilled,(state,action)=>{
            state.depositesWithPaginatioon=action?.payload;
        });
    }
});

export default depositesSlice.reducer;