import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useInsertData } from "../../../hooks/useInsertData";
import { useUpdateData } from "../../../hooks/useUpdateData";


//get all deposites for user
export const getAllDepositesForUser=createAsyncThunk(
    "/depositesSlice/getAllDeposites",
    async(query=null)=>{
        try {
            let url=`/api/v1/deposits`;
            if(query) url=`${url}?${query}`;

            const response=await useGetDataToken(url);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

export const addNewDeposite=createAsyncThunk(
    "/depositesSlice/addNewDeposite",
    async({data})=>{
        try {
            const response=await useInsertData("/api/v1/deposits",data);
           if(!data?.type) notify("تم الاضافة بنجاح","success");
           
            return response;
        } catch (error) {
            console.log('error',error)
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error?.response?.data?.message,"error");
        }
    }
);

export const updateOneDeposite=createAsyncThunk(
    "/depositesSlice/updateOneDeposite",
    async({id,data})=>{
        try {
            const response = await useUpdateData(`/api/v1/deposits/${id}`, data);
            notify("تم تعديل الطلب بنجاح", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

export const getAllDepositesForUserWithPagination=createAsyncThunk(
    "/depositesSlice/getAllDepositesForUserWithPagination",
    async(query=null)=>{
        try {
            let url=`/api/v1/deposits`;
            if(query) url=`${url}?${query}`;

            const response=await useGetDataToken(url);
            console.log("response",response)
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);