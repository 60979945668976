import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import AllPermissionsGroupsComponent from "../../Components/permissions/AllPermissionsGroupsComponent";

export default function AllPermissionsGroupsPage() {
  const screenID="665f0e8470673dba321d1609";
  const loggedUser = JSON.parse(localStorage.getItem('user'));
  if(!loggedUser){
    return  <Navigate to='/' />
   }
  // if(!loggedUser?.isAdmin)CheckForPermission(screenID);

    let access = false;
    if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
    else access=true;

    if (access == false) return <Navigate to='/setting/profile?section=Profile' />

  return (
    <div className="d-flex justify-content-start">
        <div>
            <SideBar />
        </div>
        <div className="p-3 w-100 dashboard" style={{overflow: "hidden"}}>
        <AllPermissionsGroupsComponent />
        </div>
    </div>
  )
}
